// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    :host {
        ion-card: {
            cursor: auto !important;;
        }
    }
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1haW50ZW5hbmNlLnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7SUFDSTtRQUNJO1lBQ0ksdUJBQXVCO1FBQzNCO0lBQ0oiLCJmaWxlIjoibWFpbnRlbmFuY2UudHMiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICA6aG9zdCB7XG4gICAgICAgIGlvbi1jYXJkOiB7XG4gICAgICAgICAgICBjdXJzb3I6IGF1dG8gIWltcG9ydGFudDs7XG4gICAgICAgIH1cbiAgICB9XG4gICAgIl19 */`, "",{"version":3,"sources":["webpack://./src/app/edge/settings/system/maintenance/maintenance.ts"],"names":[],"mappings":";IACI;QACI;YACI,uBAAuB;QAC3B;IACJ;;AAEJ,wXAAwX","sourcesContent":["\n    :host {\n        ion-card: {\n            cursor: auto !important;;\n        }\n    }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
