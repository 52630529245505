// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

    :host[data-isSmartPhone=true] {
      position: relative;
    }

    :host[data-isSmartPhone=false] {
      position: sticky;
      bottom: 0;
      width: 100%;

      font-size: 14px !important;
      :is(ion-row) {
        text-align: center;
      }

      :is(ion-item) {
        --min-height: initial !important;
        font-size: inherit;
      }
    }
  
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZvb3Rlci50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiOztJQUVJO01BQ0Usa0JBQWtCO0lBQ3BCOztJQUVBO01BQ0UsZ0JBQWdCO01BQ2hCLFNBQVM7TUFDVCxXQUFXOztNQUVYLDBCQUEwQjtNQUMxQjtRQUNFLGtCQUFrQjtNQUNwQjs7TUFFQTtRQUNFLGdDQUFnQztRQUNoQyxrQkFBa0I7TUFDcEI7SUFDRiIsImZpbGUiOiJmb290ZXIudHMiLCJzb3VyY2VzQ29udGVudCI6WyJcblxuICAgIDpob3N0W2RhdGEtaXNTbWFydFBob25lPXRydWVdIHtcbiAgICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgICB9XG5cbiAgICA6aG9zdFtkYXRhLWlzU21hcnRQaG9uZT1mYWxzZV0ge1xuICAgICAgcG9zaXRpb246IHN0aWNreTtcbiAgICAgIGJvdHRvbTogMDtcbiAgICAgIHdpZHRoOiAxMDAlO1xuXG4gICAgICBmb250LXNpemU6IDE0cHggIWltcG9ydGFudDtcbiAgICAgIDppcyhpb24tcm93KSB7XG4gICAgICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICAgIH1cblxuICAgICAgOmlzKGlvbi1pdGVtKSB7XG4gICAgICAgIC0tbWluLWhlaWdodDogaW5pdGlhbCAhaW1wb3J0YW50O1xuICAgICAgICBmb250LXNpemU6IGluaGVyaXQ7XG4gICAgICB9XG4gICAgfVxuICAiXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/footer/footer.ts"],"names":[],"mappings":";;IAEI;MACE,kBAAkB;IACpB;;IAEA;MACE,gBAAgB;MAChB,SAAS;MACT,WAAW;;MAEX,0BAA0B;MAC1B;QACE,kBAAkB;MACpB;;MAEA;QACE,gCAAgC;QAChC,kBAAkB;MACpB;IACF;;AAEJ,o4BAAo4B","sourcesContent":["\n\n    :host[data-isSmartPhone=true] {\n      position: relative;\n    }\n\n    :host[data-isSmartPhone=false] {\n      position: sticky;\n      bottom: 0;\n      width: 100%;\n\n      font-size: 14px !important;\n      :is(ion-row) {\n        text-align: center;\n      }\n\n      :is(ion-item) {\n        --min-height: initial !important;\n        font-size: inherit;\n      }\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
