// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .sum-state-icon > ion-icon{
    font-size: 20pt !important;
}
  
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN1bVN0YXRlLnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7RUFDRTtJQUNFLDBCQUEwQjtBQUM5QiIsImZpbGUiOiJzdW1TdGF0ZS50cyIsInNvdXJjZXNDb250ZW50IjpbIlxuICAuc3VtLXN0YXRlLWljb24gPiBpb24taWNvbntcbiAgICBmb250LXNpemU6IDIwcHQgIWltcG9ydGFudDtcbn1cbiAgIl19 */`, "",{"version":3,"sources":["webpack://./src/app/index/shared/sumState.ts"],"names":[],"mappings":";EACE;IACE,0BAA0B;AAC9B;;AAEA,gUAAgU","sourcesContent":["\n  .sum-state-icon > ion-icon{\n    font-size: 20pt !important;\n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
